<template>
  <div class="flex flex-col">
    <div class="mb-5 flex items-center justify-between lg:mb-8">
      <UiHeader
        :header="props.header"
        header-layout="section-header"
        :header-style-class="['mb-0', props.headerColor]"
      />
      <UiButton
        v-if="props.button?.link?.href"
        :to="props.button.link?.href"
        :uppercase="false"
        variant="link"
        slim
        class="hidden pr-0.5 lg:inline-flex"
        :class="props.headerColor"
      >
        {{ props.button.text }}
      </UiButton>
    </div>

    <div class="custom-scrollbar--transparent -mr-8 flex flex-nowrap gap-4 overflow-x-auto pb-2 lg:overflow-x-hidden">
      <UiVideoShowcaseItem
        v-for="(video, index) in props.mappedVideos"
        :key="index"
        :video="video"
        @click="emit('click', index)"
      />
    </div>
    <UiButton
      v-if="props.button?.link?.href"
      :to="props.button.link?.href"
      :uppercase="false"
      variant="link"
      slim
      class="mt-5 self-start lg:hidden"
      :class="props.headerColor"
    >
      {{ props.button.text }}
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import UiHeader from '../../UiHeader/UiHeader.vue'
import UiButton from '../../UiButton/UiButton.vue'
import UiVideoShowcaseItem from '../UiVideoShowcaseItem/UiVideoShowcaseItem.vue'
import type { T3CeRawlplugShowcaseProps } from '../../../../rawlplug-cms/components/T3CeRawlplugShowcase/T3CeRawlplugShowcase.types'

const props = defineProps<Partial<T3CeRawlplugShowcaseProps>>()
const emit = defineEmits(['click'])
</script>
